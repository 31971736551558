:global {
  .popover-header {
    color: black;
  }
}

.searchitem {

  overflow-x: visible;

  .days {
      white-space: nowrap;
  }

  :global {

    .g-0 {
      overflow-x: visible;
    }

    .dropdown {
      padding-right: 0!important;
      margin-right: 0!important;

      &:before {
        display: none;
      }
    }

    .download {
      SPAN.info-icon {
        margin: 0 -9px 0 5px;
        I {
          color: rgba(255, 255, 255, 1.0);
        }
      }
    }
    
    .dropdown-toggle:after {
      display: none;
    }

    .dropdown-menu-end {
      right: 0;
    }

    .btn-add-to-cart,
    .dropdown button.btn-add-to-cart {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 10px;
      border-width: 0!important;
      padding: 12px 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000!important;
      margin-right: 0;
    }
  }
}
