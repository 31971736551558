.registrationName {
  height: 0;
  overflow: hidden;
}
.closeContainer {
  position: relative;
  BUTTON {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.modalMessage {
  A {
    color: #0d6efd;
    &:hover {
      //color: #C958FF;
      text-decoration: underline;
    }
  }
}

.userInterests {
  :global {
    LABEL.form-label {
      color: black;
      display: block;
      text-align: left;
      padding-top: 15px;
    }

    .validation-hint {
      color: gray;
    }
  }
}

.artistItem {
    text-align: left;
    color: black;
}

DIV[class$='-ValueContainer'] {
    .artistItem {
        IMG {
            display: none;
        }
    }
}

.modalMessage,
.resetpassword,
.download {
  :global {
    @media (max-width: 991.98px) {
      .box {
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
      }
    }
    @media (max-width: 767.98px) {
      .box {
        width: 100%;
        top: 220px;
        left:0;
        transform: none;
      }
    }
  }
}

.download {
  H3 {
    font-size: 18px!important;
  }
  P {
    font-size: 14px!important;
    font-weight: 500!important;
  }
  :global {
    .box {
      width: 80%;
      max-width: 1300px;
    }

    INS {
      zoom: 1.25; 
      -moz-transform: scale(1.25); 
      -moz-transform-origin: 0 0;
    }

    @media (max-width: 991.98px) {
      .box {
        width: 100%;
      }
    }

    .item-right {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media (max-width: 767.98px) {
      .box {
        top: 50%;
        left:0;
        transform: translate(0, -50%);
      }
    }
  }
}