.socialHolder {
  BUTTON {
    min-height: 54px;
    border-radius: 10px!important;
    //width: max(calc(50% - 12px), 240px)!important;
    //margin: 5px auto!important;
  }
}

.paypalButtonHolder {
  zoom: 1.25; 
  -moz-transform: scale(1.25); 
  -moz-transform-origin: 0 0;
}