/*Extra small devices (portrait phones, less than 320px)*/
@media  (max-width: 320px){
.mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
    font-size: 14px;
}
	
	
	
	
}
/*Extra small devices (portrait phones, less than 360px)*/
@media  (max-width: 360px){
	
	
	
	
	
	
	
	
}
/*Extra small devices (portrait phones, less than 375px)*/
@media  (max-width: 375px){
	
	
	
	
	
	
}
/*Extra small devices (portrait phones, less than 414px)*/
@media  (max-width: 414px){
	
	
	
	
	
	
	
}
 /*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
header .container-fluid {
    padding: 0 15px;
}
.wrapper .left-item {
    position: relative;
    position: static;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
    max-height: 100%;
    background: #13161c;
    padding: 20px 20px 20px 20px;
    
}
.wrapper .left-item .item2 a {
    margin-right: 5px;
}
.mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
    padding: 8px 20px;
}
.search-bar a {
    margin-left: 5px;
    font-size: 12px;
}
.search-bar form {
    position: relative;
    width: 75%;
}
.search-bar form button {
    left: auto;
    border: 0;
    right: 0;
}
.search-bar form input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 35px;
    font-size: 14px;
}
.search-bar form button img {
    max-width: 18px;
}
.search-bar {
    position: relative;
    margin-bottom: 25px;
    margin-top: 20px;
}
.sidenav {  padding-top: 20px;
}



.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #13161c;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav button {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
  background: transparent;
  border: 0;
}

.sidenav button:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidenav {padding-top: 15px;}
.wrapper .container-fluid {
    padding: 0 15px;
}

.wrapper {
    padding-left: 0;
}
.mide-item {
    padding-bottom: 80px;
}
.mide-item .mide-banner {
    padding: 30px 30px;
}
.mide-item .mide-banner .item-img img {
    display: none;
}
.wrapper .container-fluid {
    padding: 0 15px;
}
.mide-item .Genre-inner .item-body .item .download {
    text-align: center !important;
    margin-top: 16px;
}
.mide-item .top-songs .slider a .title-item .singer-name {
    font-size: 12px;
    line-height: 18px;
}
.mide-item .top-songs .slider a .title-item .songstitle {
    font-size: 14px;
    line-height: 22px;
}
.mide-item .mide-banner .item h1 {
    font-size: 30px;
    line-height: 40px;
}

.mide-item .mide-banner .item p {
    font-size: 18px;
    line-height: 24px;
}
.mide-item .banner-artitst .box-title {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    position: static;
    bottom: 0;
}
.mide-item .banner-artitst .box-title h1 {
    font-size: 28px;
    line-height: 50px;
}
.mide-item .banner-artitst .box-title a {
    font-size: 14px;
}
.mide-item .price-text ul {
    text-align: center;
    margin-top: 20px;
}

.Register {
    overflow: auto;
    
}
.Register .item-left.item-left2 {
    padding-bottom: 40px;
    padding-top: 40px;

}
.Register .box {
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    z-index: 2;
    text-align: center;
    border-radius: 10px;
    width: auto;
    padding: 15px;
}
.Register .item-right {
    background: #fff;
    padding: 40px 40px;
}

.Register .item-right {
    border-radius: 0;
}
.Register .item-left {
    border-radius: 0;
}
.mide-item .Profile {
    margin-bottom: 40px;
}
.mide-item .Profile .item p {
    font-size: 22px;
    line-height: 32px;
}
.mide-item .Profile .item h3 {
    font-size: 40px;
}
.mide-item .Search_Results .box .accordion-item {
    padding: 30px 20px;
}
.mide-item .Search_Results .box ul li {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-right: 5px;
    margin-bottom: 5px;
}
.mide-item .Search_Results .box .accordion-item .accordion-button{
    font-size: 14px;
}

.mide-item .Search_Results .box .accordion-item .search_link_all ul li a {
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 12px;
}
.mide-item .nav_body .dropdown button {
    padding: 10px 15px;
    font-size: 12px;
}

.mide-item .nav_body .media-body .dropdown button {
  font-size: 16px;
}

.mide-item .nav_body .dropdown {
    padding-right: 10px;
    margin-right: 10px;
}
.mide-item .nav_body .dropdown:before {
    height: 20px;
}

.mide-item .nav_body ul li button {
    font-size: 12px;
    padding: 8px 12px;
    line-height: 17px;
}
.Register .item-left {
    border-radius: 0;
    padding: 40px 0;
}










}

@media (min-width: 576px) {
  .song-product-buttons {
    display: none;
  }

  .song-info-full .media-body {
    position: relative;
    padding-right:125px;
  }

  .mide-item .Genre-inner .item-body .item .song-info-full .media-body .download {
    margin-top: 0;
    position:absolute;
    right:0;
    top:0;
    display: block;
  }
}


/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
header .container-fluid {
    padding: 0 15px;
}
.wrapper .left-item {
    position: relative;
    position: static;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
    max-height: 100%;
    background: #13161c;
    padding: 20px 20px 20px 20px;
    
}
.wrapper .left-item .item2 a {
    margin-right: 5px;
}
.mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
    padding: 8px 20px;
}
.search-bar a {
    margin-left: 5px;
    font-size: 12px;
}
.search-bar form {
    position: relative;
    width: 75%;
}
.search-bar form button {
    left: auto;
    border: 0;
    right: 0;
}
.search-bar form input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 35px;
    font-size: 14px;
}
.search-bar form button img {
    max-width: 18px;
}
.search-bar {
    position: relative;
    margin-bottom: 25px;
    margin-top: 20px;
}
.sidenav {  padding-top: 20px;
}



.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #13161c;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav button {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
  background: transparent;
  border: 0;
}

.sidenav button:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.mide-item .Search_Results .box .accordion-item {
    padding: 30px 20px;
}


.wrapper {
    padding-left: 0;
}
.mide-item {
    padding-bottom: 80px;
}
.mide-item .mide-banner {
    padding: 40px 40px;
}
.mide-item .mide-banner .item-img img {
    display: none;
}
.Register {
    overflow: auto;
    
}
.Register .item-left.item-left2 {
    padding-bottom: 40px;
    padding-top: 40px;

}
.Register .box {
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    z-index: 2;
    text-align: center;
    border-radius: 10px;
    padding: 15px;
}
.Register .item-right {
    background: #fff;
    padding: 40px 40px;
}

.Register .item-right {
    border-radius: 0;
}
.Register .item-left {
    border-radius: 0;
}
.mide-item .Profile {
    margin-bottom: 40px;
}
.Register .item-left {
    border-radius: 0;
    padding: 40px 0;
}
	
	
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
header .container-fluid {
    padding: 0 15px;
}
.wrapper .left-item {
    position: relative;
    position: static;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
    max-height: 100%;
    background: #13161c;
    padding: 20px 20px 20px 20px;
    
}
.wrapper .left-item .item2 a {
    margin-right: 5px;
}
.mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
    padding: 8px 20px;
}
.search-bar a {
    margin-left: 5px;
    font-size: 12px;
}
.search-bar form {
    position: relative;
    width: 75%;
}
.search-bar form button {
    left: auto;
    border: 0;
    right: 0;
}
.search-bar form input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 35px;
    font-size: 14px;
}
.search-bar form button img {
    max-width: 18px;
}
.search-bar {
    position: relative;
    margin-bottom: 25px;
    margin-top: 20px;
}
.sidenav {  padding-top: 20px;
}



.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #13161c;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav button {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
  background: transparent;
  border: 0;
}

.sidenav button:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.Register {
    overflow: auto;
    
}
.Register .item-left.item-left2 {
    padding-bottom: 40px;
    padding-top: 40px;

}
.Register .box {
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    z-index: 2;
    text-align: center;
    border-radius: 10px;
    padding: 15px;
}
.Register .item-right {
    background: #fff;
    padding: 40px 40px;
}

.Register .item-right {
    border-radius: 0;
}
.Register .item-left {
    border-radius: 0;
}

.mide-item .Profile {
    margin-bottom: 40px;
}


.Register .item-left {
    border-radius: 0;
    padding: 40px 0;
}









.wrapper {
    padding-left: 0;
}
.mide-item {
    padding-bottom: 80px;
}
.mide-item .mide-banner {
    padding: 40px 40px;
}
.mide-item .mide-banner .item-img img {
    display: none;
}
.wrapper .container-fluid {
    padding: 0 15px;
}















}

@media (max-width: 991.98px) {
  .mide-item .mide-banner {
    margin-bottom: 0;
  }

  .item-body {
    padding-bottom: 40px;
  }
}

/*@media (min-width: 992px) {*/
  /*initial html was coded on screen without scaling but supposed to be scaled version, so everything too big with scaling*/
  /*apply 80% scaling to compensate recommended 125% scaling on 1920x1080 FullHD laptops*/
  BODY {
    zoom: 0.8; 
    -moz-transform: scale(0.8); 
    -moz-transform-origin: 0 0;
  }

  /*fix subpixel scaling (disappearing left border)*/
  header .collapse .navbar-nav .nav-item.button1::before {
    left: -1px;
    width: 101%;
  }
/*}*/

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    
	
	
	
	
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) and (max-width: 1439.98px) {
    
    
	
	
	
	
}



/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 1440px) and (max-width: 1699.98px){}





/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 1700px) and (max-width: 2099.98px){
	
	
	
	
	
	
}



/*// Extra large devices (large desktops, 1440px and up)*/
@media (min-width: 2100px){
	
	
	
	
}












