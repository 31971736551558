.header {
    text-align: center;
    margin-top: 5px;
    text-transform: capitalize;
}

.letters {
  :global {
    BUTTON {
      width: 38px;
      padding: 6px 0;
      margin-right: 3px;
      border: none;
      &:hover,
      &.active {
        background: #efefef;
        color: #000000;
      }
      &:last-child {
        width: 78px;
      }
    }
  }
}

.banner {
    position: relative; 

    padding-top: 170px;
    padding-top: min(calc(100vw*0.3), 170px);
    border-radius: 14px 14px 0 0;
    overflow: hidden;

    margin-bottom: 30px;

    .bannerImage {
        width: 100%;
        height: 100%;
    
        background-size: cover;
    
        background-position: 0% 20%;
    
        filter: contrast(.8);

        position: absolute;
        top: 0;
        z-index: 1;
    }

    .bannerInner {
        margin-bottom: 0;
        position: relative;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 40%);
        z-index: 2;

        padding-top: 30px;

        P {
            margin: 0;
            padding: 5px 0 20px;
        }
    }
}

