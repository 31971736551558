@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800;900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@300;400;500;600;700;800;900&display=swap');*/
html{
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #a768ff;
  /* background-color: rgb(28, 173, 254);
  background-color: #a4ff5d; */
}

.mide-item .Genre-inner .item-body .item .media-body .download {
  float:right;
  margin-top:8px;
}

.mide-item .Genre-inner .item-body .item .song-product-buttons .download {
  margin: 0 0 20px;
}

.mide-item .Genre-inner .item-body .item .song-info-full .media-body .download {
  display: none;
}

body{
	font-family: 'Heebo', sans-serif;
	font-weight: 400;
	position: relative;
	color: #FFFFFF;
	background: #0E1116;
}
h1,h2,h3,h4,h5,h6{
	font-weight: 700;
}
a{
	display: inline-block;
	transition: all ease .6s;
	text-decoration: none;
	color: #FFFFFF;
}
a:hover{
	text-decoration: none;
}
small{
	display: inline-block;
}
ul{
	margin: 0;
	list-style: none;
	padding: 0;
}
/* svg path{
fill: red;
} */
/* .container{
max-width: 1300px;
} */
/* header start */
header {
    background: #13161c;
    padding: 0px 0;
}
header .container-fluid{
	padding: 0 40px ;
	/* padding-left: 0; */
}
/* header .navbar {
    padding: 0;
} */
header .container-fluid{}
header .navbar-brand {
    /* padding: 0; */
    /* width: 19.2%; */
    /*background: #3b3cb8;*/
}
header .navbar-brand img {
  max-width: 280px; 
}
header .navbar-toggler{}
header .navbar-toggler svg{
	width: 20px;
}
header .navbar-toggler .navbar-toggler-icon{}
header .collapse {
	padding-left: 180px;
}
.search-bar{
	position: relative;
}
.search-bar form{
	position: relative;
}
.search-bar form input {
	background: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	border: none;
	width: 380px;
	padding-left: 50px;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 16px;
	color: #fff;
}
.search-bar form input:focus{
	background: rgba(255, 255, 255, 0.1);
	color: #fff;
}
.search-bar form button img {
/* max-width: 18px; */
}
.search-bar form button {
	position: absolute;
	top: 50%;
	left: 15px;
	background: transparent;
	border: 0;
	transform: translateY(-50%);
}
.search-bar a {
    margin-left: 10px;
    color: rgb(255 255 255 / 50%);
}
.search-bar a img{
	margin-left: 5px;
}
header .collapse .navbar-nav{}
header .collapse .navbar-nav .nav-item {
    align-self: center;
}
header .collapse .navbar-nav .nav-item.text-nav-link{}
header .collapse .navbar-nav .nav-item.text-nav-link a{
	font-weight: 500;
font-size: 16px;
line-height: 19px;
/* identical to box height */
color: #FFFFFF;
}
header .collapse .navbar-nav .nav-item.text-nav-link a span{}
header .collapse .navbar-nav .nav-item.Profile-nav-link {}
header .collapse .navbar-nav .nav-item.Profile-nav-link a {
	font-weight: 500;
font-size: 16px;
line-height: 19px;
/* identical to box height */
color: #FFFFFF;
}
header .collapse .navbar-nav .nav-item.Profile-nav-link a img {
    margin-right: 5px;
}
header .collapse .navbar-nav .nav-item.Profile-nav-link a span {}
header .collapse .navbar-nav .nav-item.button1 {}
header .collapse .navbar-nav .nav-item.button1 {}
header .collapse .navbar-nav .nav-item.button1 {
/* padding: 12px 45px; */
position: relative;
z-index: 9;
margin-left: 15px ;
}
header .collapse .navbar-nav .nav-item.button1:hover .nav-link{
	background: linear-gradient(91.31deg, #fff 0%, #fff 99.79%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
header .collapse .navbar-nav .nav-item.button1 .nav-link {
	background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	padding: 0;
	z-index: 9;
	position: relative;
	padding: 12px 45px;
}
header .collapse .navbar-nav .nav-item.button1::before{
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
	border-radius: 15px;
	z-index: -9;
}
header .collapse .navbar-nav .nav-item.button1::after {
	position: absolute;
	content: "";
	left: 1px;
	top: 1px;
	width: 98%;
	height: 96%;
	background: rgb(19 22 28);
	border-radius: 15px;
	z-index: -9;
}
header .collapse .navbar-nav .nav-item.button1:hover::after {
	background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
}
header .collapse .navbar-nav .nav-item.button2 .nav-link{
	background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
	box-shadow: 0px 10px 30px rgba(208, 73, 255, 0.5);
	border-radius: 15px;
	color: #fff;
	margin-left: 15px;
}
header .collapse .navbar-nav .nav-item.button2 .nav-link{
	padding: 12px 45px;
}
header .collapse .navbar-nav .nav-item .nav-link .noti-cuarcale {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    color: #FFFFFF;
    border-radius: 50%;
    background: #F6543E;
    display: inline-block;
    text-align: center;
    padding-top: 1.1px;
    position: absolute;
    right: 3px;
    bottom: 3px;
}
header .collapse .navbar-nav .nav-item .nav-link{
	position: relative;
}
header .collapse .navbar-nav .nav-item .nav-link .dot-cuarcale {
	background: #F6543E;
	height: 5.408604145050049px;
	width: 5.408604145050049px;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	top: 14px;
	right: 8px;
}
header .collapse .navbar-nav .nav-item .nav-link svg {}
header .collapse .navbar-nav .nav-item .nav-link svg path{}
/* header end */
/* wrapper start */
.wrapper {
    padding-top: 5.5%;
    padding-left: 18.666667%;
    min-height: 100vh;
}
.wrapper .container-fluid {
    padding: 0 25px;
}
.wrapper .left-item {
    position: fixed;
    top: 67px;
    left: 0;
    max-width: 18.666667%;
    width: 18.666667%;
    height: calc(100% - 67px);
    transition: all 0.3s ease;
    max-height: 100%;
    overflow: hidden;
    background: #13161c;
    overflow-y: auto;
    padding: 60px 40px 40px 40px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.wrapper .left-item::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.wrapper .left-item .inner-left-item{}
.wrapper .left-item .item1{}
.wrapper .left-item .item1 h4{
	font-weight: 500;
font-size: 14px;
line-height: 21px;
margin-bottom: 20px;
color: #FFFFFF;

opacity: 0.5;
}
.wrapper .left-item .item1 ul{
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-bottom: 20px;
}
.wrapper .left-item .item1 ul li:last-child{
/* margin-bottom: 0; */
}
.wrapper .left-item .item1 ul li {
    margin-bottom: 15px;
}
.wrapper .left-item .item1 ul li a {
    transition: all ease .6s;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    opacity: .5;
}
.wrapper .left-item .item1 ul li a.active,
.wrapper .left-item .item1 ul li a:hover{
	background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	opacity: 1;
}
.wrapper .left-item .item1 ul li a.active{
	position: relative;
}
.wrapper .left-item .item1 ul li a.active::before {
    position: absolute;
    content: "";
    top: -8px;
    left: -8px;
    background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
    height: 8px;
    width: 8px;
    border-radius: 50%;
}
.wrapper .left-item .item1 ul li a svg{
	margin-right: 10px;
}
.wrapper .left-item .item1 ul li a svg{}
.wrapper .left-item .item1 ul li a:hover svg linearGradient stop:first-child {
	stop-color: #E649FF;
}
.wrapper .left-item .item1 ul li a:hover svg linearGradient stop:last-child {
	stop-color: #A768FF;
}
.wrapper .left-item .item2 {}
.wrapper .left-item .item2 a{
	font-weight: 500;
font-size: 13px;
line-height: 20px;
text-decoration-line: underline;
color: #FFFFFF;
opacity: 0.3;
}
.wrapper .left-item .item2 a{}
.wrapper .left-item .item2 p{
	font-weight: 500;
font-size: 13px;
line-height: 20px;
color: #FFFFFF;
opacity: 0.3;
margin-top: 10px;
margin-bottom: 0;
}
/* mide-item start */
.mide-item {
    padding-top: 20px;
}

.mide-item .mide-banner {
    background-image: url(./img/bg-1.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 30px 60px;
    position: relative;
    border-radius: 20px;
    margin-bottom: 90px;
}
.mide-item .mide-banner .item{}
.mide-item .mide-banner .item h1{
	font-weight: 700;
font-size: 40px;
line-height: 60px;
color: #FFFFFF;
margin-bottom: 0;
}
.mide-item .mide-banner .item p{
	font-weight: 400;
font-size: 24px;
line-height: 36px;
margin-bottom: 10px;
color: #FFFFFF;
}
.mide-item .mide-banner .item a{
	background: #FFFFFF;
box-shadow: 0px 10px 30px rgba(208, 73, 255, 0.5);
border-radius: 15px;
padding: 15px 50px;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #000000;
}
.mide-item .mide-banner .item-img{}
.mide-item .mide-banner .item-img img {
    position: absolute;
    right: 60px;
    top: 0;
}
.mide-item .Top-Artists{
	padding: 60px 0;
}
.mide-item .Top-Artists h4 {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.mide-item .Top-Artists .top-artist-banner{}
.mide-item .Top-Artists .banner-item{
	margin: 0 15px;
}
.mide-item .Top-Artists .banner-item a{}
.mide-item .Top-Artists .banner-item a img{
	display: block;
	margin: auto;
	width: 70px;
height: 70px;
border-radius: 50%;
margin-bottom: 10px;
}
.mide-item .Top-Artists .banner-item a .p{
	font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
margin-bottom: 0;
}
.mide-item .top-songs{}
.mide-item .top-songs h4 img{
	margin-left: 5px;
}
.mide-item .top-songs h4{
	margin-bottom: 40px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.mide-item .top-songs .top-songs-slider{}
.mide-item .top-songs .slider{
	margin: 0 10px;
}
.mide-item .top-songs .slider a{}
.mide-item .top-songs .slider a img {
    width: 100%;
    margin: auto;
}
.mide-item .top-songs .slider a img:first-child {
    z-index: 9;
    position: relative;
    filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.7));
    border-radius: 20px;
}
.mide-item .top-songs .slider a img:nth-child(2) {
    margin-top: -15px;
    margin-bottom: 15px;
}
.mide-item .top-songs .slider a .title-item .singer-name{
   font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
opacity: 0.5;
}
.mide-item .top-songs .slider a .title-item .songstitle{
	font-weight: 600;
font-size: 16px;
line-height: 30px;
color: #FFFFFF;
}
.mide-item .top-songs .slider a .button {
    margin-top: 8px;
    background: rgb(255 255 255 / 0.1);
    /* opacity: 0.1; */
    border-radius: 18px;
    padding: 6px 16px;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.mide-item .Genre {
	padding: 60px 0;
}
.mide-item .Genre .list-all{
	padding-bottom: 40px;
}
.mide-item .Genre .list-all li{
	display: inline-block;
	margin-right: 5px;
}
.mide-item .Genre .list-all li:last-child{
	margin-right: 0;
}
.mide-item .Genre .list-all li h4{
	margin-right: 10px;
}
.mide-item .Genre .list-all li a.active{
	background: rgba(255, 255, 255, 0.7);
	color: #000000;
}
.mide-item .Genre .list-all li a{
	    margin-top: 8px;
    background: rgb(255 255 255 / 0.1);
    /* opacity: 0.1; */
    border-radius: 18px;
    padding: 6px 16px;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.mide-item .Genre-inner{}
.mide-item .Genre-inner .item-body{}
.mide-item .Genre-inner .item-body h4{
	font-weight: 700;
font-size: 20px;
line-height: 30px;
color: #FFFFFF;
margin-bottom: 40px;
}
.mide-item .Genre-inner .item-body .item{
	padding: 10px 5px;
	border-radius: 5px;
}
.mide-item .Genre-inner .item-body .item:hover{
	background: linear-gradient(180deg, #20252E 0%, rgba(32, 37, 46, 0) 100%);
}
.mide-item .Genre-inner .item-body .item:hover .media .media-body .midi-badge a {
    background: #efefef;
    color: #000000;
}
.mide-item .Genre-inner .item-body .item:hover .media .media-body a span {
    color: rgb(0 0 0 / 50%);
}
.mide-item .Genre-inner .item-body .item:hover .download a:last-child{
	background: #fff;
}
.mide-item .Genre-inner .item-body .item .media{

}

.mide-item .Genre-inner .item-body .item .media a.d-block {
	position: relative;
	margin-right: 20px;
}
.mide-item .Genre-inner .item-body .item .play_video {
    position: relative;
    margin-right: 20px;
}
.mide-item .Genre-inner .item-body .item .button-play {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    background: #0000007d;
    border: none;
    color: #fff;
    font-size: 26px;
}
.mide-item .Genre-inner .item-body .item a .button-play {
	opacity: 0;
	transition: all ease .6s;
}
.mide-item .Genre-inner .item-body .item a:hover .button-play {
	opacity: 1;
}
.mide-item .Genre-inner .item-body .item .button-play i{}
.mide-item .Genre-inner .item-body .item .media  img{}
.mide-item .Genre-inner .item-body .item .media img:nth-child(2) {
    position: absolute;
    right: -10px;
    bottom: -5px;
    z-index: 9;
}

.mide-item .Genre-inner .item-body .item .media .media-body .midi-badge {
    display: inline-block;
    margin: 10px 5px 0 0;

    &:last-child{
      margin-right: 0;
    }
}
.mide-item .Genre-inner .item-body .item .media .media-body .song-h2-artists {
  display: block;
  font-weight: 400;
  color: rgba(255,255,255,0.8);
  
	font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;

  A {
    color: rgba(255,255,255,0.8);

    &:hover {
      color: #0a58ca;
    }
  }
}

.mide-item .Genre-inner .item-body .item .media .media-body .song-h1-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
    color: #FFFFFF;
}

.mide-item .Genre-inner .item-body .item .media .media-body a span{
	font-weight: 400;
font-size: 10px;
line-height: 15px;
text-transform: capitalize;
color: rgb(255 255 255 / 50%);
}

.mide-item .Genre-inner .item-body .item .media .media-body .midi-badge a {
    margin-top:  0;
    padding: 6px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
}

.mide-item .Genre-inner .item-body .item .download{}
.mide-item .Genre-inner .item-body .item .download a {
    margin-right: 10px;
}
.mide-item .Genre-inner .item-body .item .download a{}
.mide-item .Genre-inner .item-body .item .download a img {
    width: 25px;
}
.mide-item .Genre-inner .item-body .item .download a:last-child {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    margin-right: 0;
}
.mide-item .Genre-inner .item-body .item .download a:last-child:hover{
	background: #fff;
	color: #000;
}
.mide-item video {
  width: 100%;
  height: auto;
}
.mide-item .Load-More{}
.mide-item .Load-More a {
    /* opacity: 0.7; */
    border: 1px solid rgb(255 255 255 / 70%);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: rgb(255 255 255 / 70%);
    transition: all ease .6s;
}
.mide-item .Load-More a:hover{
	border-color: #fff;
	color: #fff;
}
.mide-item .video_play .box-item{
	background: linear-gradient(180deg, #20252E 0%, rgba(32, 37, 46, 0) 100%);
border-radius: 15px;
padding: 35px;
margin-top: 30px;
position: relative;
}
.mide-item .video_play .box-item img {
    width: 40px;
    position: absolute;
    top: -15px;
    right: -15px;
}
.mide-item .video_play .box-item h4 {
    font-weight: 400;
    color: rgba(255,255,255,0.8);

    font-size: 16px;
    line-height: 24px;
    border-bottom: 2px solid rgb(255 255 255 / 20%);
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.mide-item .video_play .box-item p{
	font-weight: 400;
  color: rgba(255,255,255,0.8);
  
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
}
.mide-item .video_play .box-item p:last-child{}
.mide-item .Genre-inner .item-body .item.active a .button-play {
    opacity: 1;
    border-radius: 10px;
}

.mide-item .Genre-inner .item-body .item.active .download a:last-child{
	background: #fff;
}
.mide-item .Genre-inner .item-body .item.active{
	background: linear-gradient(180deg, #20252E 0%, rgba(32, 37, 46, 0) 100%);
}
.mide-item .banner-artitst{
	position: relative;
}
.mide-item .banner-artitst img{
	border-radius: 15px;
}
.mide-item .banner-artitst .box-title {
    padding-left: 60px;
    padding-bottom: 40px;
    padding-right: 60px;
    position: absolute;
    bottom: 0;
}
.mide-item .banner-artitst .box-title h1{
	font-weight: 700;
font-size: 64px;
line-height: 96px;
/* identical to box height */


color: #FFFFFF;
}
.mide-item .banner-artitst .box-title a{
	background: rgba(255, 255, 255, 0.1);
border-radius: 10px;
padding: 6px 20px;
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-right: 5px;
text-transform: capitalize;
color: #FFFFFF;
}
.mide-item .banner-artitst .box-title a:last-child{
	margin-right: 0;
}
.mide-item .price-text{}
.mide-item .price-text ul{}
.mide-item .price-text ul li{
	display: inline-block;
	font-weight: 400;
font-size: 24px;
line-height: 36px;
text-transform: capitalize;
color: #FFFFFF;
margin-right: 15px;
}
.mide-item .price-text ul li{}
.mide-item .price-text ul li button {
    border: none;
    background: transparent;
    color: rgb(255 255 255 / 50%);
    border: 2px solid rgb(255 255 255 / 50%);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 18px;
    line-height: 100%;
}
.mide-item .price-text ul li button:hover{
	color: #bb0505;
	border-color: #bb0505;
}
.mide-item .item-body2 {
    background: linear-gradient(180deg, #20252E 0%, rgba(32, 37, 46, 0) 100%);
    border-radius: 10px;
    padding: 10px 20px 30px;
}
.mide-item .Price-Summary {
    border-radius: 10px;
    padding: 10px 20px 20px;
    margin-top: 20px;
    background: linear-gradient(180deg, #20252E 0%, rgba(32, 37, 46, 0) 100%);
}
.mide-item .Price-Summary h4{
	font-weight: 400;
font-size: 24px;
line-height: 36px;
color: #FFFFFF;
margin-bottom: 30px;
}
.mide-item .Price-Summary .box{}
.mide-item .Price-Summary .box ul{
	margin-bottom: 30px;
}
.mide-item .Price-Summary .box ul li{
	display: inline-block;
	font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #FFFFFF;
margin-right: 20px;

}
.mide-item .Price-Summary .box ul li:last-child{
	margin-right: 0;
}
.mide-item .Price-Summary .box ul li img{
	margin-left: 5px;
}
.mide-item .Price-Summary .box h6{
	font-weight: 500;
font-size: 24px;
line-height: 36px;
margin-bottom: 0;
}
.mide-item .Price-Summary .box h6 span{
	margin-left: 20px;
}
.mide-item .Price-Summary .box button{
	background: #FFFFFF;
border-radius: 10px;
padding: 15px 25px;
font-weight: 400;
font-size: 16px;
line-height: 24px;
width: 100%;
max-width: 100%;
border: none;
text-align: center;
margin-top: 20px;
text-transform: capitalize;
color: #000000;
display: block;
}
.mide-item .nav_body{}
.mide-item .nav_body h1{
	margin-bottom: 30px;
	font-weight: 700;
font-size: 32px;
line-height: 48px;
}
.mide-item .nav_body ul{}
.mide-item .nav_body .nav-pills .nav-link.active, .mide-item .nav_body .nav-pills .show>.nav-link {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}
.mide-item .nav_body ul li{}
.mide-item .nav_body ul li:last-child{
	margin-left: 10px;
}
.mide-item .nav_body ul li button {
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255 255 255 / 50%);
    border-bottom: 2px solid rgb(255 255 255 / 50%);
    border-radius: 0;
}
.mide-item .nav_body .dropdown {
    padding-right: 15px;
    margin-right: 20px;
}
.mide-item .nav_body .dropdown-menu li:last-child{
    margin-left: 0px;
}
.mide-item .nav_body .dropdown:before {
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    width: 2px;
    height: 30px;
    background: rgb(255 255 255 / 20%);
    transform: translateY(-50%);
}
.mide-item .nav_body .dropdown button{
	border: 1px solid #FFFFFF;
box-sizing: border-box;
filter: drop-shadow(0px 10px 50px rgba(167, 104, 255, 0.5));
border-radius: 10px;
padding: 15px 20px;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
}
.mide-item .Profile{}
.mide-item .Profile h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: rgb(255 255 255 / 50%);
    margin-bottom: 18px;
}
.mide-item .Profile .item{}
.mide-item .Profile .item h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255 255 255 / 50%);
    margin-bottom: 2px;
}
.mide-item .Profile .item p{
	font-weight: 400;
  color: rgba(255,255,255,0.8);
  
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;
  padding-bottom: 35px;
}
.mide-item .Profile .item h3{
	font-weight: 700;
font-size: 48px;
line-height: 72px;
color: #FFFFFF;
}
.mide-item .Profile .item-link{}
.mide-item .Profile .item-link a{
	border: 1px solid #FFFFFF;
box-sizing: border-box;
border-radius: 10px;
padding: 15px 25px;
font-weight: 400;
font-size: 16px;
line-height: 24px;
transition: all ease .6s;
text-transform: capitalize;
color: #FFFFFF;
}
.mide-item .Profile .item-link a:hover{
	background: #fff;
	color: #000;
}
.mide-item .Profile .item-link a:last-child{
	margin-left: 10px;
}
.mide-item .Search_Results{}
.mide-item .Search_Results .box{}
.mide-item .Search_Results .box ul{}
.mide-item .Search_Results .box ul li{
	display: inline-block;
	font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
margin-right: 10px;
}
.mide-item .Search_Results .box .accordion-item .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px;
    font-size: 1rem;
    color: #ffffff;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgb(255 255 255 / 50%);
    border-radius: 0 !important;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.mide-item .Search_Results .box .accordion-item .accordion-button.collapsed::after ,
.mide-item .Search_Results .box .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  
  margin-left: 8px;
}
.mide-item .Search_Results .box .accordion-item .accordion-button:focus {
    z-index: 3;
    border-color: #fff;
    outline: 0;
    box-shadow: none;
}
.mide-item .Search_Results .box .accordion-item .accordion-body {
    padding: 0;
    padding-top: 25px;
}
.mide-item .Search_Results .box .accordion-item .search_link_all{}
.mide-item .Search_Results .box .accordion-item .search_link_all ul{

}
.mide-item .Search_Results .box .accordion-item .search_link_all ul li:last-child{
	margin-right: 0;
}
.mide-item .Search_Results .box .accordion-item .search_link_all ul li {
    margin-right: 3px;
}
.mide-item .Search_Results .box .accordion-item .search_link_all ul li a:hover{
	color: #fff;
	border-color: #fff;
}
.mide-item .Search_Results .box .accordion-item .search_link_all ul li a {
    border: 1px solid rgb(255 255 255 / 70%);
    box-sizing: border-box;
    filter: drop-shadow(0px 10px 50px rgba(167, 104, 255, 0.5));
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 10px;
    color: rgb(255 255 255 / 70%);
    transition: all ease .6s;
}
.mide-item .Search_Results .box .accordion-item .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    border-color: #fff;
}
.mide-item .Search_Results .box .accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 20px !important;
    padding: 40px 79px;
    background: linear-gradient(91.31deg, #A768FF 0%, #A0DDFF 99.79%);
   
    position: relative;
    z-index: 9;
}
.mide-item .Search_Results .box .accordion-item:after{
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	width: 100%;
	height: 100%;
	 background-image: url(./img/search-accordion-bg.png);
	z-index: -9;
	opacity: .7;

}
.mide-item .Search_Results .box ul li .dropdown{}
.mide-item .Search_Results .box ul li .dropdown button {
    border: 0;
    border-bottom: 1px solid #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border-radius: 0;
    background: transparent;
    padding: 5px;
}
.mide-item .filter_body{}
.mide-item .filter_body .dropdown{
	margin-bottom: 35px;
}
.mide-item .filter_body select {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 10px 30px 10px 20px;
    border: 0;
    color: #fff;
    max-width: 260px;
    text-align: center;
    margin-bottom: 35px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  
}
.mide-item .filter_body select option {
    color: #000;
    padding: 0;
    margin: 0;
    text-align: left;
}
.mide-item .filter_body  .item{
	background: #13161C;
border-radius: 10px;
padding: 10px 20px 15px;
margin-bottom: 30px;
}
.mide-item .filter_body .item h4{
	font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height */
color: rgba(255, 255, 255, 0.6);
margin: 10px 0px;
}
.mide-item .filter_body .item ul{}
.mide-item .filter_body .item ul li{}
.mide-item .filter_body .item ul li a{
	font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
margin: 5px 0px;
}
.mide-item{}
.mide-item{}
.mide-item{}
.mide-item{}
.mide-item{}
.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
.media-body {
    -ms-flex: 1;
    flex: 1;
}
/* mide-item end */
/* wrapper end */
.Register {
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgb(49 52 56 / 70%);
    top: 0;
    left: 0;
    z-index: 9999;
}
.Register .box {
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4);
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 800px;
    max-width: 100%;
    text-align: center;
    /* overflow: hidden; */
    border-radius: 10px;
}
.Register .item-left {
    background-image: url(./img/Sign-in.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    padding-top: 185px;
    border-radius: 10px 0px 0 10px;
}
.Register .item-left.item-left2 {
    padding-top: 105px;
}
.Register .item-left h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 15px;
}
.Register .item-left p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
    padding-bottom: 25px;
}
.Register .item-left a{
	border: 1px solid #FFFFFF;
box-sizing: border-box;
border-radius: 5px;
padding: 13px 64px;
transition: all ease .6s;
}
.Register .item-left a:hover{
	background: #fff;
	color: #000;
}
.Register .item-right {
    background: #fff;
    padding: 40px 90px;
    border-radius: 0 10px 10px 0px;
}
.Register .item-right h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    color: #0E1116;
    margin-bottom: 18px;
}
.Register .item-right .google img{
	margin-right: 8px;
}
.Register .item-right .google {
    border: 1px solid #0E1116;
    box-sizing: border-box;
    background: transparent;
    padding: 5px 8px 5px 8px;
}
.Register .item-right p{
	font-weight: 400;
font-size: 12px;
line-height: 140.4%;
text-align: center;
color: #9A9A9A;
margin-top: 20px;
}
.Register .item-right form .form-check-label a{
	font-weight: 600;
font-size: 10px;
line-height: 140.4%;
/* identical to box height, or 14px */

text-align: center;
text-decoration-line: underline;

/* grad 2 */

background: linear-gradient(91.31deg, #A768FF 0%, #A0DDFF 99.79%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.blur-item{
	filter: blur(4px);
  -webkit-filter: blur(4px);
}
.Register .item-right form .form-check-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 140.4%;
    text-align: left;
    color: #9A9A9A;
}
.Register .item-right form .from-item {
    position: relative;
   
}
.Register .item-right form .from-item label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}
.Register .item-right form .from-item input{
	padding-left: 40px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-right: 20px;
	 background: rgba(14, 17, 22, 0.05);
	 border-radius: 0;
	 border: 0;
	 font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #9A9A9A;
}
.Register .item-right .mb-3 {
    margin-bottom: 0.5rem!important;
}
.Register .item-right form button{
	background: linear-gradient(91.31deg, #A768FF 0%, #A0DDFF 99.79%);
border-radius: 5px;
padding: 10px 58px;
border: none;
margin-top: 20px;
}
.mobile-bottom-menu {
    background: #000;
    padding: 15px 0;
}
.mobile-bottom-menu ul{}
.mobile-bottom-menu ul li{
	margin-right: 5px;
}
.mobile-bottom-menu ul li:last-child{
	margin-right: 0;
}
.mobile-bottom-menu ul li a {
    position: relative;
    padding: 5px 5px;
}.mobile-bottom-menu ul li{
	display: inline-block;
}
.mobile-bottom-menu ul li.button1 a, .mobile-bottom-menu ul li.button2 a {
    background: linear-gradient(91.31deg, #E649FF 0%, #A768FF 99.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding: 0;
    z-index: 9;
    position: relative;
    padding: 10px 25px;
    border: 1px solid #E649FF;
    border-radius: 10px;
}
.mobile-bottom-menu ul li .dot-cuarcale {
    background: #F6543E;
    height: 5.408604145050049px;
    width: 5.408604145050049px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 6px;
}


/*========================== Mission-Statement start ================================*/
.Mission-Statement {
  /*color: #000;*/
  text-align: center;
  padding-top: 10px;
}
.Mission-Statement h2{
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 30px;
text-align: center;
/*color: #000000;*/
margin-bottom: 10px;
}
.Mission-Statement p{
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align: center;
margin-bottom: 0;
/*color: #000000;*/
}
/*========================== Mission-Statement end ================================*/
/*========================== Music-Producers start ================================*/
.Music-Producers {
/*color: #000;*/
padding: 50px 0;
}
.Music-Producers A {
  text-decoration: underline;
}
.Music-Producers A:hover {
  text-decoration: none;
}
.Music-Producers .item-left {}
.Music-Producers .item-left img{}
.Music-Producers .item-right{}
.Music-Producers .item-right h4{
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 30px;
/*color: #000000;*/
margin-bottom: 10px;
}
.Music-Producers .item-right p{
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
margin-bottom: 0;
/*color: #000000;*/
}
.Music-Producers.Music-Producers2 {}
/*========================== Music-Producers end ================================*/
