.container {
  position: fixed;

  right: 15px;
  top: 71px;

  @media (min-width: 992px) {
    right: 15px;
    top: 80px;
  }

  :global {
    .modal-content {
      background-color:transparent;
      border: none;
      .modal-header {
        padding: 8px;
        border:none;
        //background-color:rgba(167,104,255,0.9);
        background-color:rgba(163,176,244,0.9);
        
        .btn-close {
          font-size: 12px;
        }
      }
      .modal-body {
        padding: 0;
      }
    }
  }
}

.mobilePreviewWrapper {
  zoom:1.25;
  margin-left:-4px;
  margin-right:-4px;
  width:calc(100% + 8px);
}

:global(.cart-container) .mobilePreviewWrapper {
  margin-left:-20px;
  margin-right:-20px;
  width:calc(100% + 40px);
}

.responsiveYoutube {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 204px;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  // &.paused {
  //     padding-top: 109.375%;
  //     margin-top: -30.5625%;
  // }

  /* Then style the iframe to fit in the container div with full height and width */
  IFRAME {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
  }
}