.main {
    min-height: 52vh;

    //color: black;
}

section.mainSection {
    //background: white;
    border: 1px solid #f0f0f0;
    padding: 20px 10px 20px 20px;
    margin-top: 18px;

    A {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }

    // a:hover {
    //     border-top: 1px solid #ffffff81;
    //     outline-color: none;
    // }
}
